import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import {
    Alert,
    Button,
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import {
    GET_ALL_GROUPS_URL,
    GET_MEETING_BY_ID_URL,
    LOGIN_ROUTE,
    SUN_EDITOR_BUTTONS_LIST,
    SUN_EDITOR_DEFAULT_STYLE,
    TIME_PERIOD_FOR_MEETING_UPDATE_CHECK,
} from "../../constants";
import { validateEmptyField } from "../utils/validate";
import ProjectNavbar from "../widgets/projectNavbar";
import "./../../styles/editMeeting.css";
import axios from "axios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import _ from "lodash";
import { arrangeOrders } from "../utils/arrangeOrders";
import { allowDrop, drag, drop } from "../utils/dragNdrop";
import { handleFileDownload } from "../utils/fileDownload";
import { getMeetingHistory } from "../utils/getMeetingHistory";
import { showMeetingHistory } from "../widgets/meetingHistory";
import MeetingHeaderWithHistory from "../widgets/meetingHeaderWithHistory";
import { disableEditMeeting } from "../utils/disableEditMeeting";
import handleEditMeeting from "../utils/handleEditMeeting";
import { getGroupByName } from "../utils/getGroupByNameOrId";
import handleRemoveFileFromFilesToBeUploaded from "../utils/handleRemoveFileFromFilesToBeUploaded";
import handleFileDelete from "../utils/handleFileDelete";
import MeetingDetails from "../widgets/MeetingDetails";
import FileDescriptionModal from "../widgets/fileDescriptionModal";
import CompareMeeting from "../utils/compareMeeting";
import useInterval from "../utils/useInterval";
import MeetingUpdatedModal from "../widgets/meetingUpdatedModal";
import { useRef } from "react";
import AddItemsPopup from "../widgets/addItemsPopup";
import { getLocalTimeFromUTC } from "../utils/timeConverts";
import { capitalize } from "../utils/stringUtils";
import updateOrders from "../utils/updateOrders";

const EditMeeting = () => {
    const authReducer = useSelector((state) => state.authReducer);
    const jwt = authReducer.jwt;
    const currentUser = authReducer.currentUser;

    const dragRef = useRef([]);
    const hoverRef = useRef([]);

    const { id } = useParams();

    const [showPopup, setShowPopup] = useState(false);
    const [showPopupIndex, setShowPopupIndex] = useState(null);
    const [isPopupPosUp, setIsPopupPosUp] = useState(true); // is the popup position on the top of the item or not (this is only used for the last item)
    const [readOnly, setReadOnly] = useState(false);
    const [showTopicDescriptionLabel, setShowTopicDescriptionLabel] =
        useState(false);
    const [topicDescriptionLabelIndex, setTopicDescriptionLabelIndex] =
        useState(null);

    const [meetingSettings, setMeetingSettings] = useState({
        show_name_in_packet: true,
        show_title_in_packet: true,
        show_date_in_packet: true
    });

    const [name, setName] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [title, setTitle] = useState(null);
    const [titleError, setTitleError] = useState(null);
    const [date, setDate] = useState(null);
    const [dateError, setDateError] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [startTimeError, setStartTimeError] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [endTimeError, setEndTimeError] = useState(null);
    const [isMeetingFinalized, setIsMeetingFinalized] = useState(null);
    const [isMinutesFinalized, setIsMinutesFinalized] = useState(null);
    const [editMeetingError, setEditMeetingError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [remove_items, setRemoveItems] = useState([]);
    const [update_items_id_type_list, setUpdateItemsIdTypeList] = useState([]);
    let update_items = [];
    const [filesToBeUploaded, setFilesToBeUploaded] = useState([]);
    const [minuteFilesToBeUploaded, setMinuteFilesToBeUploaded] = useState([]);
    const [showFileDescriptionModal, setShowFileDescriptionModal] =
        useState(false);
    const [selectedFileEvent, setSelectedFileEvent] = useState(null);
    const [selectedFileIsMinuteFile, setSelectedFileIsMinuteFile] =
        useState(false);
    const [selectedFileTopicIndex, setSelectedFileTopicIndex] = useState(null);
    const [isMeetingUpdated, setIsMeetingUpdated] = useState(false);

    const LOCAL_NAME = "Name";
    const LOCAL_NAME_PACKET = "Show Name On Packet";
    const LOCAL_TITLE = "Title";
    const LOCAL_TITLE_PACKET = "Show Title On Packet";
    const LOCAL_DATE = "Date";
    const LOCAL_DATE_PACKET = "Show Date On Packet";
    const LOCAL_START_TIME = "Start time";
    const LOCAL_END_TIME = "End time";
    const LOCAL_GROUP = "Group";
    const LOCAL_MEETING_FINALIZED = "Meeting Finalized";
    const LOCAL_MINUTES_FINALIZED = "Minutes Finalized";
    const LOCAL_TOPIC_NUMBERING = "Topic Numbering";

    const [groups, setGroups] = useState([]);
    const [group, setGroup] = useState({});

    const [topicNumbering, setTopicNumbering] = useState(null);

    const [originalMeeting, setOriginalMeeting] = useState(null);
    const [meeting, setMeeting] = useState({
        meetingSettings: {
            show_name_in_packet: true,
            show_title_in_packet: true,
            show_date_in_packet: true
        },
        name: null,
        title: null,
        date: null,
        start_time: null,
        end_time: null,
        topic_numbering: null,
        items: [
            {
                id: null,
                description: null,
                order: 1,
                is_active: 1,
                type: null,
            },
        ],
    });

    const [meetingHistory, setMeetingHistory] = useState(null);
    const [isShowMeetingHistory, setIsShowMeetingHistory] = useState(false);

    const fetchMeeting = async () => {
        const response = await axios.get(GET_MEETING_BY_ID_URL + `/${id}`, {
            headers: { Authorization: `Bearer ${jwt}` },
        });

        setMeetingSettings({
            show_name_in_packet: response.data.meeting.show_name_in_packet,
            show_title_in_packet: response.data.meeting.show_title_in_packet,
            show_date_in_packet: response.data.meeting.show_date_in_packet,
        });
        setName(response.data.meeting.name);
        setTitle(response.data.meeting.title);
        setTopicNumbering(response.data.meeting.topic_numbering);
        setDate(response.data.meeting.date);
        const startTime = getLocalTimeFromUTC(
            `${response.data.meeting.start_time} +0000`,
            currentUser.current_client_timezone
        );
        setStartTime(
            `${startTime.getHours().toString().padStart(2, "0")}:${startTime
                .getMinutes()
                .toString()
                .padStart(2, "0")}`
        );
        const endTime = getLocalTimeFromUTC(
            `${response.data.meeting.end_time} +0000`,
            currentUser.current_client_timezone
        );
        setEndTime(
            `${endTime.getHours().toString().padStart(2, "0")}:${endTime
                .getMinutes()
                .toString()
                .padStart(2, "0")}`
        );
        setIsMeetingFinalized(response.data.meeting.is_finalized);
        setIsMinutesFinalized(response.data.meeting.is_minutes_finalized);
        setMeeting(response.data.meeting);
        setGroup({
            id: response.data.meeting.group_id,
            name: response.data.meeting.group_name,
        });
        setOriginalMeeting(response.data.meeting);
    };

    const fetchGroups = async () => {
        const response = await axios(GET_ALL_GROUPS_URL + `?per_page=50`, {
            headers: { Authorization: `Bearer ${jwt}` },
        });

        setGroup(response.data.groups.data[0]);
        setGroups(response.data.groups.data);
    };

    useEffect(() => {
        fetchGroups();
        fetchMeeting();
    }, []);

    useInterval(async () => {
        if (!(await CompareMeeting(id, jwt, originalMeeting)))
            setIsMeetingUpdated(true);
    }, TIME_PERIOD_FOR_MEETING_UPDATE_CHECK);

    const handleAddItem = (e, type, addIndex, addPos) => {
        e.preventDefault();

        if (addPos === "BOTTOM") ++addIndex;

        let localMeeting = _.cloneDeep(meeting);
        const itemsLength = localMeeting.items.length;
        if (type === "AGENDA") {
            localMeeting.items.splice(addIndex, 0, {
                id: null,
                description: null,
                order: itemsLength + 1,
                is_active: 1,
                type: "AGENDA",
            });
        } else if (type === "TOPIC") {
            localMeeting.items.splice(addIndex, 0, {
                id: null,
                name: null,
                description: null,
                order: itemsLength + 1,
                minute: null,
                is_private_file: false,
                is_private_topic: false,
                is_active: 1,
                type: "TOPIC",
            });
        }

        arrangeOrders([...localMeeting.items], "MEETING");
        updateOrders(meeting, localMeeting.items, update_items_id_type_list, setUpdateItemsIdTypeList, checkIfItemExistsInUpdateList);
        setMeeting(localMeeting);
    };

    const checkIfItemExistsInUpdateList = (array, meeting, itemIndex) => {
        if (array.length === 0) return false;
        return (
            array.find(
                (element) =>
                    element["id"] === meeting.items[itemIndex].id &&
                    element["type"] === meeting.items[itemIndex].type
            ) !== null
        );
    };

    const handleCopy = (e, itemIndex, item) => {
        e.preventDefault();

        const originalItemOrder = item.order;
        let localMeeting = _.cloneDeep(meeting);

        localMeeting.items.splice(itemIndex + 1, 0, item);
        localMeeting.items[itemIndex + 1].id = null;
        localMeeting.items[itemIndex + 1].order = originalItemOrder + 1;

        arrangeOrders([...localMeeting.items], "MEETING");
        updateOrders(meeting, localMeeting.items, update_items_id_type_list, setUpdateItemsIdTypeList, checkIfItemExistsInUpdateList);
        setMeeting(localMeeting);
    };

    const handleDelete = (e, itemIndex) => {
        e.preventDefault();

        let localMeeting = _.cloneDeep(meeting);
        localMeeting.items = localMeeting.items.filter(
            (item, index) => index !== itemIndex
        );
        if (meeting.items[itemIndex].id !== null) {
            setRemoveItems([
                ...remove_items,
                {
                    id: meeting.items[itemIndex].id,
                    type: meeting.items[itemIndex].type,
                },
            ]);
            if (
                !checkIfItemExistsInUpdateList(
                    update_items_id_type_list,
                    localMeeting,
                    itemIndex
                )
            ) {
                setUpdateItemsIdTypeList(
                    update_items_id_type_list.filter(
                        (item_id_type) =>
                            item_id_type.id !== meeting.items[itemIndex].id &&
                            item_id_type.type !== meeting.items[itemIndex].type
                    )
                );
            }
        }
        arrangeOrders([...localMeeting.items], "MEETING");
        updateOrders(meeting, localMeeting.items, update_items_id_type_list, setUpdateItemsIdTypeList, checkIfItemExistsInUpdateList);
        setMeeting(localMeeting);
    };

    const handleEditorChange = (content, itemIndex) => {
        // here we are using a closure inside setTemplate()
        // otherwise inside the SunEditor component we were receiving older value of the meeting state
        // whereas, using closure gives us the current value
        setMeeting((meeting) => {
            meeting.items[itemIndex].description = content;
            // check if updated item is an existing item or newly added item
            if (meeting.items[itemIndex].id !== null)
                if (
                    !checkIfItemExistsInUpdateList(
                        update_items_id_type_list,
                        meeting,
                        itemIndex
                    )
                )
                    setUpdateItemsIdTypeList([
                        ...update_items_id_type_list,
                        {
                            id: meeting.items[itemIndex].id,
                            type: meeting.items[itemIndex].type,
                        },
                    ]);
            return meeting;
        });
    };

    async function handleInputChange(e, type) {
        // these three checks are for settings checkboxes
        if (type === LOCAL_NAME_PACKET) setMeetingSettings({...meetingSettings, show_name_in_packet: !meetingSettings.show_name_in_packet});
        else if (type === LOCAL_TITLE_PACKET) setMeetingSettings({...meetingSettings, show_title_in_packet: !meetingSettings.show_title_in_packet});
        else if (type === LOCAL_DATE_PACKET) setMeetingSettings({...meetingSettings, show_date_in_packet: !meetingSettings.show_date_in_packet});
        else{
            // don't want preventDefault happening when checkboxes are checked
            e.preventDefault();
        }

        clearSuccessAndErrorMessages();
        if (type === LOCAL_NAME) {
            e.target.value = capitalize(e.target.value);
            setName(e.target.value);
        }
        if (type === LOCAL_TITLE) {
            e.target.value = capitalize(e.target.value);
            setTitle(e.target.value);
        }
        if (type === LOCAL_DATE) setDate(e.target.value);
        if (type === LOCAL_START_TIME) setStartTime(e.target.value);
        if (type === LOCAL_END_TIME) setEndTime(e.target.value);
        if (type === LOCAL_GROUP) {
            const matchedGroup = getGroupByName(e.target.value, groups);

            setGroup({
                name: matchedGroup["name"],
                id: matchedGroup["id"],
            });
        }
        if (type === LOCAL_MEETING_FINALIZED) {
            setIsMeetingFinalized(!isMeetingFinalized);
        }
        if (type === LOCAL_MINUTES_FINALIZED) {
            setIsMinutesFinalized(!isMinutesFinalized);
        }

        if (type === LOCAL_TOPIC_NUMBERING) setTopicNumbering(e.target.value);
    }

    function handleTopicChange(e, itemIndex, type) {
        e.preventDefault();

        clearSuccessAndErrorMessages();

        let localMeeting = _.cloneDeep(meeting);
        if (type === "TOPIC NAME")
            localMeeting.items[itemIndex].name = e.target.value;
        else if (type === "TOPIC DESCRIPTION")
            localMeeting.items[itemIndex].description = e.target.value;
        else if (type === "TOPIC MINUTE")
            localMeeting.items[itemIndex].minute = e.target.value;
        else if (type === "TOPIC IS PRIVATE")
            localMeeting.items[itemIndex].is_private_topic =
                !meeting.items[itemIndex].is_private_topic;
        else if (type === "TOPIC FILE IS PRIVATE")
            localMeeting.items[itemIndex].is_private_file =
                !meeting.items[itemIndex].is_private_file;

        setMeeting(localMeeting);

        // check if updated item is an existing item or newly added item
        if (localMeeting.items[itemIndex].id !== null)
            if (
                !checkIfItemExistsInUpdateList(
                    update_items_id_type_list,
                    localMeeting,
                    itemIndex
                )
            )
                setUpdateItemsIdTypeList([
                    ...update_items_id_type_list,
                    {
                        id: localMeeting.items[itemIndex].id,
                        type: localMeeting.items[itemIndex].type,
                    },
                ]);
    }

    function clearSuccessAndErrorMessages() {
        setNameError(null);
        setTitleError(null);
        setDateError(null);
        setStartTimeError(null);
        setEndTimeError(null);
        setEditMeetingError(null);
        setSuccessMessage(null);
    }

    function validateAll() {
        // if meeting is disabled then only minutes and minute files are editable so no need of validation
        if (disableEditMeeting(meeting)) return true;
        if (!validateEmptyField(name)) {
            setNameError("Please fill this field!");
            return false;
        }
        if (!validateEmptyField(title)) {
            setTitleError("Please fill this field!");
            return false;
        }
        if (!validateEmptyField(date)) {
            setDateError("Please fill this field!");
            return false;
        } else {
            const dateSelected = new Date(date);
            const currentDate = new Date();
            // get only the dates for comparison therefore setting the hours to zero
            dateSelected.setHours(0, 0, 0, 0);
            currentDate.setHours(0, 0, 0, 0);
            if (dateSelected < currentDate) {
                setDateError("Please select a proper date!");
                return false;
            }
        }
        if (!validateEmptyField(startTime)) {
            setStartTimeError("Please fill this field!");
            return false;
        } else {
            const startTimeSelected = new Date(
                `${date} ${startTime}`
            ).getTime();
            if (startTimeSelected <= new Date().getTime()) {
                setStartTimeError(
                    "Please make sure that Start time is after the Current time!"
                );
                return false;
            }
        }

        if (!validateEmptyField(endTime)) {
            setEndTimeError("Please fill this field!");
            return false;
        } else {
            if (startTime >= endTime) {
                setEndTimeError(
                    "Please make sure that End time is after the Start time!"
                );
                return false;
            }
        }

        for (let i = 0; i < meeting.items.length; i++) {
            if (
                meeting.items[i].type === "TOPIC" &&
                !validateEmptyField(meeting.items[i].name)
            ) {
                setEditMeetingError("Please fill all the item name fields");
                return false;
            }
            if (
                meeting.items[i].type === "AGENDA" &&
                !validateEmptyField(meeting.items[i].description)
            ) {
                setEditMeetingError("Please fill all the text section fields");
                return false;
            }
        }
        return true;
    }

    const checkForUpdateItems = () => {
        console.log(`UPDATE checkForUpdateItems, update items list = ${JSON.stringify(update_items_id_type_list)}`);
        update_items_id_type_list.forEach((updatedItemIdType) => {
            const originalItem = originalMeeting.items.filter(
                (item) =>
                    item.id === updatedItemIdType.id &&
                    item.type === updatedItemIdType.type
            )[0];
            const updatedItem = meeting.items.filter(
                (item) =>
                    item.id === updatedItemIdType.id &&
                    item.type === updatedItemIdType.type
            )[0];

            if (originalItem.order === updatedItem.order) {
                delete updatedItem["order"];
            }

            update_items.push(updatedItem);
        });
        console.log(`update_items = ${JSON.stringify(update_items)}`);
    };

    return (
        <>
            {jwt === null ? (
                // if the user is not logged in then redirect to login
                <Redirect to={LOGIN_ROUTE} />
            ) : (
                <div className="container-lg">
                    <ProjectNavbar />
                    <div className="container-fluid">
                        {isMeetingUpdated ? <MeetingUpdatedModal /> : null}
                        {showFileDescriptionModal ? (
                            <FileDescriptionModal
                                setShowFileDescriptionModal={
                                    setShowFileDescriptionModal
                                }
                                fileEvent={selectedFileEvent}
                                topicIndex={selectedFileTopicIndex}
                                topic={meeting.items[selectedFileTopicIndex]}
                                isMinuteFile={selectedFileIsMinuteFile}
                                meeting={meeting}
                                setMeeting={setMeeting}
                                minuteFilesToBeUploaded={
                                    minuteFilesToBeUploaded
                                }
                                setMinuteFilesToBeUploaded={
                                    setMinuteFilesToBeUploaded
                                }
                                filesToBeUploaded={filesToBeUploaded}
                                setFilesToBeUploaded={setFilesToBeUploaded}
                            />
                        ) : null}
                        <MeetingHeaderWithHistory
                            headingText="Edit Meeting"
                            getMeetingHistory={getMeetingHistory}
                            id={id}
                            jwt={jwt}
                            setMeetingHistory={setMeetingHistory}
                            setIsShowMeetingHistory={setIsShowMeetingHistory}
                            isShowMeetingHistory={isShowMeetingHistory}
                            showMeetingHistory={showMeetingHistory}
                            meetingHistory={meetingHistory}
                        />

                        {successMessage !== null ? (
                            <Alert
                                color="success"
                                toggle={(e) => {
                                    e.preventDefault();
                                    setSuccessMessage(null);
                                }}
                            >
                                {successMessage}
                            </Alert>
                        ) : null}
                        {editMeetingError !== null ? (
                            <Alert
                                color="danger"
                                toggle={(e) => {
                                    e.preventDefault();
                                    setEditMeetingError(null);
                                }}
                            >
                                {editMeetingError}
                            </Alert>
                        ) : null}
                        <Form
                            onSubmit={(e) =>
                                handleEditMeeting(
                                    e,
                                    id,
                                    jwt,
                                    clearSuccessAndErrorMessages,
                                    validateAll,
                                    meeting,
                                    meetingSettings,
                                    name,
                                    title,
                                    date,
                                    group,
                                    startTime,
                                    endTime,
                                    isMeetingFinalized,
                                    topicNumbering,
                                    checkForUpdateItems,
                                    remove_items,
                                    // [...update_items],
                                    update_items,
                                    disableEditMeeting,
                                    setSuccessMessage,
                                    minuteFilesToBeUploaded,
                                    filesToBeUploaded,
                                    setEditMeetingError,
                                    setNameError,
                                    setMeeting,
                                    setOriginalMeeting,
                                    originalMeeting,
                                    isMinutesFinalized,
                                    setRemoveItems,
                                    setUpdateItemsIdTypeList
                                )
                            }
                        >
                            <MeetingDetails
                                currentUser={currentUser}
                                meeting={meeting}
                                meetingSettings={meetingSettings}
                                handleInputChange={handleInputChange}
                                name={name}
                                nameError={nameError}
                                title={title}
                                titleError={titleError}
                                date={date}
                                dateError={dateError}
                                startTime={startTime}
                                startTimeError={startTimeError}
                                endTime={endTime}
                                endTimeError={endTimeError}
                                groups={groups}
                                group={group}
                                isMeetingFinalized={isMeetingFinalized}
                                isMinutesFinalized={isMinutesFinalized}
                                topicNumbering={topicNumbering}
                            />
                            {/* agendas */}
                            <div className="mb-2 mt-3">
                                <AddItemsPopup
                                    handleAddItem={handleAddItem}
                                    addIndex={meeting.items.length}
                                    disabled={disableEditMeeting(meeting)}
                                />
                                {meeting.items.map((item, itemIndex) => {
                                    return (
                                        <div
                                            key={item.id}
                                            ref={(el) =>
                                            (hoverRef.current[itemIndex] =
                                                el)
                                            }
                                            onMouseOver={(e) => {
                                                e.preventDefault();

                                                const mouseY = e.clientY;
                                                const elementY =
                                                    dragRef.current[
                                                        itemIndex
                                                    ].getBoundingClientRect().y;
                                                const elementHeight =
                                                    dragRef.current[
                                                        itemIndex
                                                    ].getBoundingClientRect()
                                                        .height;

                                                setShowPopup(true);
                                                if (
                                                    mouseY - elementY >
                                                    elementHeight / 2
                                                ) {
                                                    if (itemIndex === meeting.items.length - 1) {
                                                        setIsPopupPosUp(false);
                                                        setShowPopupIndex(itemIndex);
                                                    }
                                                    else
                                                        setShowPopupIndex(itemIndex + 1);
                                                }
                                                else {
                                                    if (itemIndex === meeting.items.length - 1)
                                                        setIsPopupPosUp(true);
                                                    setShowPopupIndex(itemIndex);
                                                }
                                            }}
                                            onMouseLeave={(e) => {
                                                e.preventDefault();
                                                setShowPopup(false);
                                                setShowPopupIndex(null);
                                            }}
                                        >
                                            <div className={`d-flex justify-content-center p-1 mt-3 ${showPopup
                                                && itemIndex === showPopupIndex
                                                && !(itemIndex === meeting.items.length - 1 && !isPopupPosUp) // This part is only to hide this popup for the last item when the bottom popup of the last item is visible
                                                ? "visible" : "invisible"}`}>
                                                <AddItemsPopup
                                                    handleAddItem={
                                                        handleAddItem
                                                    }
                                                    addIndex={itemIndex}
                                                    addPos="TOP"
                                                    buttonSize="sm"
                                                />
                                            </div>
                                            <div
                                                className="agenda-wrapper border p-2 mt-3"
                                                key={`${item.type} ${item.order}`}
                                                draggable={false}
                                                ref={(el) =>
                                                (dragRef.current[
                                                    itemIndex
                                                ] = el)
                                                }
                                                onDragStart={(event) => {
                                                    setReadOnly(true);
                                                    setUpdateItemsIdTypeList([
                                                        ...update_items_id_type_list,
                                                        {
                                                            id: meeting.items[
                                                                itemIndex
                                                            ].id,
                                                            type: meeting.items[
                                                                itemIndex
                                                            ].type,
                                                        },
                                                    ]);
                                                    drag(event, itemIndex);
                                                }}
                                                onDragEnd={(event) => {
                                                    setReadOnly(false);
                                                }}
                                                onDrop={(event) => {
                                                    setReadOnly(false); // this is repeated here even after onDragEnd() because it was observed that sometimes only onDrop() is called when the drag ends
                                                    setUpdateItemsIdTypeList([
                                                        ...update_items_id_type_list,
                                                        {
                                                            id: meeting.items[
                                                                itemIndex
                                                            ].id,
                                                            type: meeting.items[
                                                                itemIndex
                                                            ].type,
                                                        },
                                                    ]);
                                                    drop(
                                                        event,
                                                        itemIndex,
                                                        meeting,
                                                        setMeeting,
                                                        "MEETING"
                                                    );
                                                }}
                                                onDragOver={(event) =>
                                                    allowDrop(event)
                                                }
                                            >
                                                <div
                                                    className="drag-wrapper bg-muted"
                                                    // using mouse enter and leave events to set draggable property as true or false
                                                    // otherwise even when someone wants to just select some item text(instead of dragging the item)
                                                    // the item was dragged and this was undesirable
                                                    onMouseEnter={(e) => {
                                                        e.preventDefault();
                                                        dragRef.current[
                                                            itemIndex
                                                        ].draggable = true;
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.preventDefault();
                                                        dragRef.current[
                                                            itemIndex
                                                        ].draggable = false;
                                                    }}
                                                >
                                                    <div className="handle">
                                                        ...
                                                    </div>
                                                    <Button
                                                        color="danger float-end"
                                                        disabled={disableEditMeeting(
                                                            meeting
                                                        )}
                                                        onClick={(e) =>
                                                            handleDelete(
                                                                e,
                                                                itemIndex
                                                            )
                                                        }
                                                    >
                                                        Delete
                                                    </Button>
                                                    <Button
                                                        color="warning float-end"
                                                        disabled={disableEditMeeting(
                                                            meeting
                                                        )}
                                                        onClick={(e) =>
                                                            handleCopy(
                                                                e,
                                                                itemIndex,
                                                                // cloneDeep is used here so that any changes made to the item in handleCopy() does not effect the existing item
                                                                _.cloneDeep(
                                                                    item
                                                                )
                                                            )
                                                        }
                                                    >
                                                        Copy
                                                    </Button>
                                                </div>
                                                {item.type === "AGENDA" ? (
                                                    /* agenda */
                                                    <div>
                                                        <SunEditor
                                                            defaultValue={
                                                                item.description
                                                            }
                                                            showToolbar={true}
                                                            disable={disableEditMeeting(
                                                                meeting
                                                            )}
                                                            readOnly={readOnly}
                                                            onChange={(e) =>
                                                                handleEditorChange(
                                                                    e,
                                                                    itemIndex
                                                                )
                                                            }
                                                            setDefaultStyle={
                                                                SUN_EDITOR_DEFAULT_STYLE
                                                            }
                                                            setOptions={{
                                                                buttonList:
                                                                    SUN_EDITOR_BUTTONS_LIST,
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    /* topic */
                                                    <div>
                                                        <div className="d-flex">
                                                            <FormGroup
                                                                floating
                                                                className="mt-3 w-75 me-3"
                                                            >
                                                                <Input
                                                                    placeholder="Enter topic name"
                                                                    className="topic-name w-100"
                                                                    name={`Topic Name ${itemIndex}`}
                                                                    id={`Topic Name ${itemIndex}`}
                                                                    type="text"
                                                                    disabled={disableEditMeeting(
                                                                        meeting
                                                                    )}
                                                                    value={
                                                                        item.name ??
                                                                        ""
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleTopicChange(
                                                                            e,
                                                                            itemIndex,
                                                                            "TOPIC NAME"
                                                                        )
                                                                    }
                                                                />
                                                                <Label
                                                                    htmlFor={`Topic Name ${itemIndex}`}
                                                                >
                                                                    Topic Name
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup
                                                                floating
                                                                className="mt-3 w-25"
                                                            >
                                                                <Input
                                                                    id={`Topic isPrivateTopic ${itemIndex}`}
                                                                    name={`Topic isPrivateTopic ${itemIndex}`}
                                                                    type="select"
                                                                    className="mb-2"
                                                                    value={
                                                                        item.is_private_topic
                                                                            ? "YES"
                                                                            : "NO"
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleTopicChange(
                                                                            e,
                                                                            itemIndex,
                                                                            `TOPIC IS PRIVATE`
                                                                        )
                                                                    }
                                                                >
                                                                    {[
                                                                        "YES",
                                                                        "NO",
                                                                    ].map(
                                                                        (
                                                                            element
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    key={
                                                                                        element
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        element
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Input>
                                                                <Label
                                                                    htmlFor={`Topic isPrivateTopic ${itemIndex}`}
                                                                >
                                                                    Private Topic
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup
                                                                floating
                                                                className="mt-3 w-25"
                                                            >
                                                                <Input
                                                                    id={`Topic isPrivateFiles ${itemIndex}`}
                                                                    name={`Topic isPrivateFiles ${itemIndex}`}
                                                                    type="select"
                                                                    className="mb-2"
                                                                    value={
                                                                        item.is_private_file
                                                                            ? "YES"
                                                                            : "NO"
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleTopicChange(
                                                                            e,
                                                                            itemIndex,
                                                                            `TOPIC FILE IS PRIVATE`
                                                                        )
                                                                    }
                                                                >
                                                                    {[
                                                                        "YES",
                                                                        "NO",
                                                                    ].map(
                                                                        (
                                                                            element
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    key={
                                                                                        element
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        element
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Input>
                                                                <Label
                                                                    htmlFor={`Topic isPrivateFiles ${itemIndex}`}
                                                                >
                                                                    Private Files
                                                                </Label>
                                                            </FormGroup>
                                                        </div>

                                                        {showTopicDescriptionLabel &&
                                                            itemIndex ===
                                                            topicDescriptionLabelIndex ? (
                                                            <Label
                                                                className="topic-description-label"
                                                                htmlFor={`Topic Description ${itemIndex}`}
                                                            >
                                                                Topic
                                                                Description
                                                            </Label>
                                                        ) : null}
                                                        <SunEditor
                                                            defaultValue={
                                                                item.description
                                                            }
                                                            showToolbar={true}
                                                            disable={disableEditMeeting(
                                                                meeting
                                                            )}
                                                            readOnly={readOnly}
                                                            onChange={(e) =>
                                                                handleEditorChange(
                                                                    e,
                                                                    itemIndex
                                                                )
                                                            }
                                                            onFocus={(e) => {
                                                                e.preventDefault();
                                                                setTopicDescriptionLabelIndex(
                                                                    itemIndex
                                                                );
                                                                setShowTopicDescriptionLabel(
                                                                    true
                                                                );
                                                            }}
                                                            onBlur={(e) => {
                                                                e.preventDefault();
                                                                setTopicDescriptionLabelIndex(
                                                                    null
                                                                );
                                                                setShowTopicDescriptionLabel(
                                                                    false
                                                                );
                                                            }}
                                                            setDefaultStyle={
                                                                SUN_EDITOR_DEFAULT_STYLE
                                                            }
                                                            setOptions={{
                                                                buttonList:
                                                                    SUN_EDITOR_BUTTONS_LIST,
                                                            }}
                                                        />

                                                        <Row className="mt-2 mb-2 select-files">
                                                            <Col>
                                                                <input
                                                                    id={`invisible-file-input ${itemIndex}`}
                                                                    type="file"
                                                                    className="d-none"
                                                                    disabled={disableEditMeeting(
                                                                        meeting
                                                                    )}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setSelectedFileEvent(
                                                                            e
                                                                        );
                                                                        setSelectedFileIsMinuteFile(
                                                                            false
                                                                        );
                                                                        setSelectedFileTopicIndex(
                                                                            itemIndex
                                                                        );
                                                                        setShowFileDescriptionModal(
                                                                            true
                                                                        );
                                                                    }}
                                                                ></input>
                                                                <Label
                                                                    htmlFor={`invisible-file-input ${itemIndex}`}
                                                                >
                                                                    <p
                                                                        className={`btn btn-outline-primary ${disableEditMeeting(
                                                                            meeting
                                                                        )
                                                                                ? "disabled"
                                                                                : null
                                                                            }`}
                                                                    >
                                                                        Upload
                                                                    </p>
                                                                </Label>
                                                            </Col>
                                                        </Row>
                                                        {"filesToBeUploaded" in
                                                            item ? (
                                                            <Row className="mt-2 mb-2 select-files">
                                                                <Label>
                                                                    Files to be
                                                                    uploaded
                                                                </Label>
                                                                {"filesToBeUploaded" in
                                                                    item
                                                                    ? item.filesToBeUploaded.map(
                                                                        (
                                                                            file,
                                                                            fileIndex
                                                                        ) => {
                                                                            return (
                                                                                <Col
                                                                                    key={
                                                                                        file.file_name
                                                                                    }
                                                                                >
                                                                                    <Card>
                                                                                        <CardBody>
                                                                                            {
                                                                                                file.file_name
                                                                                            }
                                                                                            <Button
                                                                                                color="danger"
                                                                                                className="float-end"
                                                                                                onClick={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    handleRemoveFileFromFilesToBeUploaded(
                                                                                                        e,
                                                                                                        itemIndex,
                                                                                                        file,
                                                                                                        false,
                                                                                                        meeting,
                                                                                                        setMeeting,
                                                                                                        minuteFilesToBeUploaded,
                                                                                                        setMinuteFilesToBeUploaded,
                                                                                                        filesToBeUploaded,
                                                                                                        setFilesToBeUploaded
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                Del
                                                                                            </Button>
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                </Col>
                                                                            );
                                                                        }
                                                                    )
                                                                    : null}
                                                            </Row>
                                                        ) : null}
                                                        {item.files ? (
                                                            <Row className="mt-2 mb-2 existing-files">
                                                                <Label>
                                                                    Uploaded
                                                                    Files
                                                                </Label>
                                                                {item.files
                                                                    ? item.files.map(
                                                                        (
                                                                            file
                                                                        ) => {
                                                                            return (
                                                                                <Col
                                                                                    key={
                                                                                        file.id
                                                                                    }
                                                                                >
                                                                                    <Card>
                                                                                        <CardBody>
                                                                                            {
                                                                                                file.description
                                                                                            }
                                                                                            <div className="float-end">
                                                                                                <Button
                                                                                                    color="success"
                                                                                                    className="me-2"
                                                                                                    onClick={(
                                                                                                        e
                                                                                                    ) => {
                                                                                                        handleFileDownload(
                                                                                                            e,
                                                                                                            jwt,
                                                                                                            file
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    Download
                                                                                                </Button>
                                                                                                <Button
                                                                                                    color="danger"
                                                                                                    disabled={disableEditMeeting(
                                                                                                        meeting
                                                                                                    )}
                                                                                                    onClick={(
                                                                                                        e
                                                                                                    ) => {
                                                                                                        handleFileDelete(
                                                                                                            e,
                                                                                                            itemIndex,
                                                                                                            file,
                                                                                                            meeting,
                                                                                                            setMeeting,
                                                                                                            update_items_id_type_list,
                                                                                                            setUpdateItemsIdTypeList
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    Del
                                                                                                </Button>
                                                                                            </div>
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                </Col>
                                                                            );
                                                                        }
                                                                    )
                                                                    : null}
                                                            </Row>
                                                        ) : null}

                                                        <FormGroup floating>
                                                            <Input
                                                                placeholder="Enter minute"
                                                                type="textarea"
                                                                name={`Minute ${itemIndex}`}
                                                                id={`Minute ${itemIndex}`}
                                                                value={
                                                                    item.minute ??
                                                                    ""
                                                                }
                                                                disabled={
                                                                    meeting.is_minutes_finalized
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={(e) =>
                                                                    handleTopicChange(
                                                                        e,
                                                                        itemIndex,
                                                                        "TOPIC MINUTE"
                                                                    )
                                                                }
                                                            />
                                                            <Label
                                                                htmlFor={`Minute ${itemIndex}`}
                                                            >
                                                                Minute
                                                            </Label>
                                                        </FormGroup>
                                                        {disableEditMeeting(
                                                            meeting
                                                        ) ? (
                                                            <div>
                                                                <input
                                                                    id={`invisible-file-input-minute ${itemIndex}`}
                                                                    type="file"
                                                                    className="d-none"
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setSelectedFileEvent(
                                                                            e
                                                                        );
                                                                        setSelectedFileIsMinuteFile(
                                                                            true
                                                                        );
                                                                        setSelectedFileTopicIndex(
                                                                            itemIndex
                                                                        );
                                                                        setShowFileDescriptionModal(
                                                                            true
                                                                        );
                                                                    }}
                                                                ></input>
                                                                <Label
                                                                    htmlFor={`invisible-file-input-minute ${itemIndex}`}
                                                                >
                                                                    <p
                                                                        className={`btn btn-outline-primary mt-2 
                                                                    ${meeting.is_minutes_finalized
                                                                                ? "disabled"
                                                                                : null
                                                                            } 
                                                                    `}
                                                                    >
                                                                        Upload
                                                                        Minute
                                                                        Files
                                                                    </p>
                                                                </Label>
                                                                {"minuteFilesToBeUploaded" in
                                                                    item ? (
                                                                    <Row className="mt-2 mb-2 select-files">
                                                                        <Label>
                                                                            Files
                                                                            to
                                                                            be
                                                                            uploaded
                                                                        </Label>
                                                                        {"minuteFilesToBeUploaded" in
                                                                            item
                                                                            ? item.minuteFilesToBeUploaded.map(
                                                                                (
                                                                                    file,
                                                                                    fileIndex
                                                                                ) => {
                                                                                    return (
                                                                                        <Col
                                                                                            key={
                                                                                                file.file_name
                                                                                            }
                                                                                        >
                                                                                            <Card>
                                                                                                <CardBody>
                                                                                                    {
                                                                                                        file.file_name
                                                                                                    }
                                                                                                    <Button
                                                                                                        color="danger"
                                                                                                        className="float-end"
                                                                                                        onClick={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            handleRemoveFileFromFilesToBeUploaded(
                                                                                                                e,
                                                                                                                itemIndex,
                                                                                                                file,
                                                                                                                true,
                                                                                                                meeting,
                                                                                                                setMeeting,
                                                                                                                minuteFilesToBeUploaded,
                                                                                                                setMinuteFilesToBeUploaded,
                                                                                                                filesToBeUploaded,
                                                                                                                setFilesToBeUploaded
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        Del
                                                                                                    </Button>
                                                                                                </CardBody>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    );
                                                                                }
                                                                            )
                                                                            : null}
                                                                    </Row>
                                                                ) : null}
                                                                {item.minute_files ? (
                                                                    <Row className="mt-2 mb-2 existing-files">
                                                                        <Label>
                                                                            Uploaded
                                                                            Minute
                                                                            Files
                                                                        </Label>
                                                                        {item.minute_files
                                                                            ? item.minute_files.map(
                                                                                (
                                                                                    file
                                                                                ) => {
                                                                                    return (
                                                                                        <Col
                                                                                            key={
                                                                                                file.id
                                                                                            }
                                                                                        >
                                                                                            <Card>
                                                                                                <CardBody>
                                                                                                    {
                                                                                                        file.description
                                                                                                    }
                                                                                                    <div className="float-end">
                                                                                                        <Button
                                                                                                            color="success"
                                                                                                            className="me-2"
                                                                                                            onClick={(
                                                                                                                e
                                                                                                            ) => {
                                                                                                                handleFileDownload(
                                                                                                                    e,
                                                                                                                    jwt,
                                                                                                                    file
                                                                                                                );
                                                                                                            }}
                                                                                                        >
                                                                                                            Download
                                                                                                        </Button>
                                                                                                        <Button
                                                                                                            color="danger"
                                                                                                            onClick={(
                                                                                                                e
                                                                                                            ) => {
                                                                                                                handleFileDelete(
                                                                                                                    e,
                                                                                                                    itemIndex,
                                                                                                                    file,
                                                                                                                    meeting,
                                                                                                                    setMeeting,
                                                                                                                    update_items_id_type_list,
                                                                                                                    setUpdateItemsIdTypeList
                                                                                                                );
                                                                                                            }}
                                                                                                        >
                                                                                                            Del
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </CardBody>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    );
                                                                                }
                                                                            )
                                                                            : null}
                                                                    </Row>
                                                                ) : null}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}
                                            </div>
                                            {/* This item popup at the bottom will only appear if this is the last item */}
                                            {itemIndex === meeting.items.length - 1 ?
                                                <div className={`d-flex justify-content-center p-1 mt-3 ${showPopup && !isPopupPosUp && itemIndex === showPopupIndex ? "visible" : "invisible"}`}>
                                                    <AddItemsPopup
                                                        handleAddItem={
                                                            handleAddItem
                                                        }
                                                        addIndex={itemIndex}
                                                        addPos="BOTTOM"
                                                        buttonSize="sm"
                                                    />
                                                </div> : null}
                                        </div>
                                    );
                                })}
                            </div>
                            <Button
                                color="primary"
                                type="submit"
                                className="mb-5"
                                disabled={
                                    meeting.is_minutes_finalized ? true : false
                                }
                            >
                                Save Meeting
                            </Button>
                        </Form>
                    </div>
                </div>
            )}
        </>
    );
};

export default EditMeeting;
